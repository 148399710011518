import Swal from "sweetalert2";


export const showAlert = () => {
  Swal.fire({
    title: "Success",
    text: "Your message was sent successfully",
    icon: "success",
    confirmButtonText: "OK",
  });
}