import React from "react";
import { Route, Link, Routes } from "react-router-dom";
import "./index.css";
import Home from "./pages/User/Home.jsx";
import Admin from "./pages/Admin/Admin.jsx";
import DashBoard from "./pages/Admin/DashBoard.jsx";
import NotFound from "./pages/NotFound.jsx";
import Layout from "./components/Layout.jsx";

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={
        <Layout><Home /></Layout>} />
        <Route path="/admin" element={<Layout>
          <Admin />
          </Layout>} />
        <Route path="/dashboard" element={<Layout>
          <DashBoard />
          </Layout>} />
        <Route path="*" element={<Layout>
          <NotFound />
          </Layout>} />
      </Routes>
    </>
  );
}

export default App;
