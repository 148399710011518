import React from "react";
import { Link } from "react-router-dom";

function NotFound() {
  return (
    <div className="bg-gray-900 relative w-full max-h-auto min-h-screen overflow-auto flex flex-col justify-center items-center">
    
      <h3 className="text-white text-5xl pt-20">This page could not be found</h3>
      <button className="btn bg-gray-500 p-3 rounded-md my-4">
        <Link to="/" className="text-white">click here</Link>
        </button>
      <img src="https://i.imgur.com/qIufhof.png" height="50%" className="pb-50"/>
    
    </div>
  );
}


export default NotFound;