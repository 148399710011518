import React, { useState } from "react";
import {
  PencilAltIcon,
  PhoneIcon,
  LocationMarkerIcon,
  MailIcon,
} from "@heroicons/react/solid";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faFacebook, faInstagram, faLinkedin,faGithub, faWhatsapp } from '@fortawesome/free-brands-svg-icons';

import {createMessage} from "../services/contactService"
import { showAlert } from "./SweetAlert";




const Contact=()=>{
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit= async (e) => {
    e.preventDefault();
    const payload = await createMessage(name, email, subject, message);
    showAlert();
    e.target.reset();
  }

  return (
    <section id="contact" className="relative">
      <div className="md:px-12 py-8 mx-auto sm:p-5 pt:5">
        <div className="text-center m-4 mt-4">
          <PencilAltIcon className="w-10 inline-block mb-4" />
          <h1 className="sm:text-4xl text-3xl font-medium title-font text-white mb-4">
            Let's Talk
          </h1>
        </div>
      </div>

      <div className="flex flex-col sm:flex-row px-12 mx-auto sm:flex-nowrap flex-wrap">
        <div className="sm:w-1/2 w-full p-4 justify-between">
          <h1 className="text-4xl text-white pb-10">
            Let's Build Together! 🚀
          </h1>
          <p className="text-start text-2xl">
            Whether you have a project in mind, a question, or just want to
            connect, I'm all ears! Your vision is the heartbeat of my work, and
            together, we can create something extraordinary.
          </p>

          <div className="py-10">
            <p className="text-xl">📱 Let's Chat:</p>
            
            <a href="https://twitter.com/codehouze" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faTwitter} className="m-3 text-4xl px-2x text-blue-400 hover:text-blue-300" />
          </a>
          <a href="https://facebook.com/codehouze" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faFacebook} className="m-3 text-4xl text-blue-600 hover:text-blue-500"  />
          </a>
          <a href="https://instagram.com/codehouze" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faInstagram} className="m-3 text-4xl text-pink-800 hover:text-pink-600" />
          </a>
          <a href="https://linkedin.com/in/codehouze" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faLinkedin} className="m-3 text-4xl text-blue-800 hover:text-blue-600" />
          </a>
          <a href="https://wa.me/+2348030914767" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faWhatsapp} className="m-3 text-4xl text-green-800 hover:text-green-600" />
          </a>

          </div>
        </div>
        <div className="sm:w-1/2 p-4 w-full">
          <form
            netlify
            name="contact"
            onSubmit={handleSubmit}
            className=" flex flex-col md:ml-auto w-full md:py-8 mt-8 md:mt-0"
          >
            <div className="relative mb-4">
              <label htmlFor="name" className="leading-7 text-sm text-gray-400">
                Name
              </label>
              <input
                type="text"
                id="name"
                name="name"
                className="w-full bg-gray-800 rounded border border-gray-700 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-900 text-base outline-none text-gray-100 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                onChange={(e) => setName(e.target.value)}
              />
            </div>

            <div className="relative mb-4">
              <label
                htmlFor="email"
                className="leading-7 text-sm text-gray-400"
              >
                Email
              </label>
              <input
                type="email"
                id="email"
                name="email"
                className="w-full bg-gray-800 rounded border border-gray-700 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-900 text-base outline-none text-gray-100 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="relative mb-4">
              <label htmlFor="name" className="leading-7 text-sm text-gray-400">
                Subject
              </label>
              <input
                type="text"
                id="subject"
                name="subject"
                className="w-full bg-gray-800 rounded border border-gray-700 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-900 text-base outline-none text-gray-100 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                onChange={(e) => setSubject(e.target.value)}
              />
            </div>
            <div className="relative mb-4">
              <label
                htmlFor="message"
                className="leading-7 text-sm text-gray-400"
              >
                Message
              </label>
              <textarea
                id="message"
                name="message"
                className="w-full bg-gray-800 rounded border border-gray-700 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-900 h-32 text-base outline-none text-gray-100 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"
                onChange={(e) => setMessage(e.target.value)}
              />
            </div>
            <button
              type="submit"
              onClick={showAlert}
              className="text-white bg-green-500 border-0 py-2 px-6 focus:outline-none hover:bg-green-700 rounded text-lg"
            >
              Shoot me an email
            </button>
          </form>
        </div>
      </div>
    </section>
  );
}



export default Contact