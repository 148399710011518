import React from "react";


function DownloadButton() {
  const handleDownload = () => {
    // Replace "your_file_url" with the actual URL or path of the file you want to download
    const fileUrl = "https://docs.google.com/document/d/16uCdXxQvh0nHSWvTPWInytmxGiwbuZH4SAyfnhnBSvI/edit?usp=sharing";

    // Create an anchor element
    const link = document.createElement("a");
    
// Set the href attribute to the file URL with Cloudinary transformation parameters for force download
link.href = `${fileUrl}?dl=true`;

    // Set the download attribute to specify the filename for the downloaded file
    link.download = "Resume_BE.pdf"; // You can set your desired filename here

    // Append the anchor element to the document body
    document.body.appendChild(link);

    // Trigger a click on the anchor element to start the download
    link.click();

    // Remove the anchor element from the document body
    document.body.removeChild(link);
  };

  return (
    <button onClick={handleDownload}>
      Download Resume
    </button>
  );
}

export default DownloadButton;
