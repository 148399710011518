import React, { useState, useEffect } from "react";
import profile_picture from "../asset/profile.jpg";
import avatar from "../asset/avatar_1.jpeg";
import UserProfileFlip from "./UserProfileFlip";
import "../index.css";
import DownloadButton from "./DownloadButton";

function About() {
  const [text, setText] = useState("");
  const [isPageVisited, setPageVisited] = useState(false);
  const [fullText, setFullText] = useState(
    "who is passionate about bringing ideas to life."
  );
  const [index, setIndex] = useState(0);

  useEffect(() => {
    setPageVisited(true);
  }, []);

  useEffect(() => {
    if (index < fullText.length) {
      setTimeout(() => {
        setText(text + fullText[index]);
        setIndex(index + 1);
      }, 40);
    }
  }, [index]);

  useEffect(() => {
    if (isPageVisited) {
      setIndex(fullText.length);
    }
  }, [isPageVisited]);

  return (
    <section id="about">
      <div className="mx-auto flex px-12 text-xl md:flex-row sm:pt-25 flex-col items-center">
        <div className="lg:flex-grow md:w-1/2 lg:pr-10 md:pr-10 flex flex-col md:items-start md:text-left mb-3 md:mb-0 items-center text-center sm:mt-10">
          <h1 className="title-font sm:text-4xl text-3xl my-4 font-medium text-white">
            Hi
            <span
              role="img"
              aria-label="waving hand"
              className="pl-4 waving-hand wave"
            >
              👋
            </span>
          </h1>
          <h3 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-white xm:text-2xl">
            I am a
            <span className="bg-green-700 px-2 sm:text-xm ml-1">
              Full Stack Engineer
            </span>
            <span className="inline-block">{text}</span>
          </h3>

          <div className="flex justify-center">
            <a
              className="inline-flex text-white bg-green-700 border-0 py-2 px-6 focus:outline-none hover:bg-green-600 rounded text-lg"
            >
              <DownloadButton />

            </a>

            <a
              href="#contact"
              className="ml-4 inline-flex text-gray-400 bg-gray-800 border-0 py-2 px-6 focus:outline-none hover:bg-gray-700 hover:text-white rounded text-lg"
            >
              Work With Me
            </a>
          </div>
        </div>

        <div className="mt-10 lg:max-w-lg lg:w-full md:w-1/2 w-6/6 xs:w-2/3 mx-auto rounded-lg flex justify-center items-center">
          {isPageVisited && (
            <UserProfileFlip
              frontImageUrl={avatar}
              backImageUrl={profile_picture}
            />
          )}
        </div>
      </div>
    </section>
  );
}

export default About;
