import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

function ProjectModal({ project, onClose }) {
  return (
    <div className="fixed inset-0 z-50 overflow-auto bg-opacity-100 backdrop-blur-md flex">
      <div className="relative p-10 bg-gray-900 w-full max-w-screen-md max-h-screen m-auto flex-col flex">
        <div className="flex justify-end">
          <button
            onClick={onClose}
            className="text-white bg-red-500 hover:text-gray-400 focus:outline-none p-2 mb-3 rounded-md"
          >
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </div>
        <div className="flex flex-col lg:flex-row">
          <div className="lg:w-1/2 mb-6 mx-6 mt-6">
            <img
              src={project.image}
              alt={project.title}
              className="h-auto max-w-full rounded-md"
            />
            <Link to={project.link}>
              <button
                className="text-white hover:text-gray-400 focus:outline-none mt-6 p-2 rounded-md bg-green-500"
              >
                LIVE LINK
              </button>
            </Link>
          </div>
          <div className="text-center lg:text-left text-white px-4 lg:w-1/2 mb-6 mt-6">
            <p className="text-xl font-bold pb-4 text-green-400">
              {project.title}
            </p>
            {/* <p className="text-lg font-medium">{project.subtitle}</p> */}
            <p className="text-sm p-3 lg:text-left">{project.description}</p>
            <div className="flex justify-center lg:justify-start mt-4 items-center">
              {project.Stack.map((stack, index) => (
                <span
                  key={index}
                  className="bg-gray-700 text-white rounded-md px-2 py-1 text-xs mr-2"
                >
                  {stack}
                </span>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProjectModal;
