import React from "react";
import Header from "../../components/Header";
import Counter from "../../components/count/Counter";
import Sidebar from "../../components/Sidebar";
// import Card from "../components/shared/Card";

function DashBoard() {
  return (
    <>
      <Header />
      <Sidebar />
      <Counter />
    </>
  );
}

export default DashBoard;
