import React, { useState, useEffect } from 'react';

const UserProfileFlip = ({ frontImageUrl, backImageUrl }) => {
  const [isFlipped, setIsFlipped] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsFlipped(true);
    }, 1800);
  }, []);

  return (
    <div className="relative w-72 h-72 m-auto" style={{ perspective: '1000px' }}>
      <div
        className="w-full h-full rounded-full"
        style={{
          transformStyle: 'preserve-3d',
          transform: `rotateY(${isFlipped ?  '180deg' : '0deg'})`,
          transition: 'transform 0.9s',
        }}
      >
        <div className="absolute w-full h-full fa-border rounded-full">
          <img src={isFlipped ? backImageUrl : frontImageUrl} alt={isFlipped ? 'Front':'Back'} className="w-full h-full rounded-full drop-shadow-x shadow-inherit " />
        </div>
      </div>
    </div>
  );
};

export default UserProfileFlip;
