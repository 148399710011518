import React, { useState } from "react";
import { motion, Variants } from "framer-motion";
import { useLocation, Link } from 'react-router-dom';


const itemVariants = {
  open: {
    opacity: 1,
    y: 0,
    transition: { type: "spring", stiffness: 300, damping: 24 }
  },
  closed: { opacity: 0, y: 20, transition: { duration: 0.2 } }
};
const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleIsOpen = () => {
    setIsOpen(!isOpen);
  };

  const location = useLocation();
  const isHomePage = location.pathname === '/';
  

  return (
    <nav className="bg-gray-800">
      <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
        <div className="relative flex h-16 items-center justify-between">
          <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
            {/* <!-- Mobile menu button--> */}
            <button
              type="button"
              className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
              aria-controls="mobile-menu"
              aria-expanded="false"
              onClick={toggleIsOpen}
            >
              <span className="absolute -inset-0.5"></span>
              <span className="sr-only">Open main menu</span>

              <svg
                className="block h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                />
              </svg>

              <svg
                class="hidden h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
          <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
            <div className="flex flex-shrink-0 items-center">
              <Link
                to="#about"
                className="bg-green-500 text-blue-950 rounded-md px-3 py-2 text-sm font-medium"
                aria-current="page"
              >
                Emmanuel Chijioke Maxwell
              </Link>
            </div>
            <div class="hidden sm:ml-6 sm:block">
              <div class="flex space-x-4">
           
                <Link
                  to="https://medium.com/@codehouseinc"
                  className="text-gray-300 hover:bg-gray-700 hover:text-white rounded-md px-3 py-2 text-sm font-medium"
                >
                  Blog
                </Link>
               
                {isHomePage && (  
                <> 
                <a
                  href="#projects"
                  className="text-gray-300 hover:bg-gray-700 hover:text-white rounded-md px-3 py-2 text-sm font-medium"
                  aria-current="page"
                >
                  Projects
                </a>
                <a
                  href="#services"
                  className="text-gray-300 hover:bg-gray-700 hover:text-white rounded-md px-3 py-2 text-sm font-medium"
                >
                  Technology Stack 
                </a>
                
                <a
                  href="#contact"
                  className="text-gray-300 hover:bg-gray-700 hover:text-white rounded-md px-3 py-2 text-sm font-medium"
                >
                  Contact
                </a>
                </>)}
              </div>
            </div>
          </div>
        
        </div>
      </div>

      {isOpen && (
         <motion.nav
         initial={false}
         animate={isOpen ? "open" : "closed"}
         className="menu"
       >
         
      <motion.ul
        variants={{
          open: {
            clipPath: "inset(0% 0% 0% 0% round 10px)",
            transition: {
              type: "spring",
              bounce: 0,
              duration: 0.7,
              delayChildren: 0.3,
              staggerChildren: 0.05
            }
          },
          closed: {
            clipPath: "inset(10% 50% 90% 50% round 10px)",
            transition: {
              type: "spring",
              bounce: 0,
              duration: 0.3
            }
          }
        }}
        style={{ pointerEvents: isOpen ? "auto" : "none" }}
      >
         <div className="sm:hidden" id="mobile-menu">
          <div className="space-y-1 px-2 pb-3 pt-2">
       
            <motion.li variants={itemVariants}>
            <a
              href="https://medium.com/@chijioke.emma.maxwell"
              className="text-gray-300 hover:bg-gray-900 hover:text-white block rounded-md px-3 py-2 text-base font-medium"
              onClick={() => {
                setIsOpen(!isOpen);
              }}
            >
              Blog
            </a>
            </motion.li>
            <motion.li variants={itemVariants}>
            <a
              href="#projects"
              className="text-gray-300 hover:bg-gray-900 hover:text-white block rounded-md px-3 py-2 text-base font-medium"
              onClick={() => {
                setIsOpen(!isOpen);
              }}
            >
              Project
            </a>
            </motion.li>
           
            <motion.li variants={itemVariants}>
            <a
              href="#contact"
              className="text-gray-300 hover:bg-gray-900 hover:text-white block rounded-md px-3 py-2 text-base font-medium"
              onClick={() => {
                setIsOpen(!isOpen);
              }}
            >
              Contact
            </a>
            </motion.li>
            <motion.li variants={itemVariants}>
            <a
              href="#services"
              className="text-gray-300 hover:bg-gray-900 hover:text-white block rounded-md px-3 py-2 text-base font-medium"
              onClick={() => {
                setIsOpen(!isOpen);
              }}
            >
              Technology Stack
            </a>
            </motion.li>
           
          </div>
        </div>
      
      </motion.ul>
    </motion.nav>
       

      )}
    </nav>
  );
};

export default Header;
