import React from 'react';
import { CodeIcon } from "@heroicons/react/solid";
const Stacks = require("../data/stacks.json");
const Services = () => {
    return (
        <section id="services" className="text-gray-400 bg-gray-900 body-font my-5">
        <div className="py-10 mx-auto text-center lg:px-34">
          <div className="flex flex-col w-full mb-20">
            <CodeIcon className="mx-auto inline-block w-10 mb-4" />
            <h1 className="sm:text-4xl text-3xl font-medium title-font mb-1 text-white">
              Technology Stack
            </h1>

            <div className="py-5">
            {Stacks.map(({stack,logo})=>(
                      <button className="btn text-center rounded-xl text-white p-2 m-2 sm:text-xs sm:rounded-md">
                      
                        <img src={logo} width={50} height={50} alt="logo"/>
                        {stack}
                      </button>
            ))}
                  </div>
          </div>
          </div>
          </section>
    );
};



export default Services;