import axios from "axios";


const API_URL = "https://profile-be-s13s.onrender.com/api/v1/";

export const createMessage = async (name,email,subject,message) => {
    try{ const response = await axios.post(API_URL + "contact", {
        name,email,subject,message
      });
      return response?.data
    }
    catch(err){
        console.error('Error creating contact:', err);
    }
 
};

