
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faFacebook, faInstagram, faLinkedin, faGithub} from '@fortawesome/free-brands-svg-icons';

const Footer = () => {
  return (
    <footer className="bg-gray-800 text-white p-6">
      <div className="container mx-auto text-center">
        <p className="text-lg font-bold mb-2">Connect with Us</p>
        <div className="flex justify-center space-x-4">
          <a href="https://twitter.com/codehouze" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faTwitter} className="text-2xl hover:text-blue-400" />
          </a>
          <a href="https://facebook.com/codehouze" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faFacebook} className="text-2xl hover:text-blue-600" />
          </a>
          {/* <a href="https://instagram.com/codehouze" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faInstagram} className="text-2xl hover:text-pink-500" />
          </a> */}
          <a href="https://www.linkedin.com/in/codehouze/" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faLinkedin} className="text-2xl hover:text-blue-800" />

          </a>
          <a href="https://www.github.com/codehouze/" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faGithub} className="text-2xl hover:filter hover:invert" />
          </a>

        </div>
        
      </div>
    </footer>
  );
};

export default Footer;

