import React from "react";
import About from "../../components/About";
import Contact from "../../components/Contact";
import ShowCaseProjects from "../../components/ShowCaseProjects";
import Services from "../../components/Services";

function Home() {
  return (
    <main className="text-gray-400 bg-gray-900 body-font">
      <About />
      <ShowCaseProjects />
      <Services />
      {/* <Testimonials /> */}
      <Contact />
    </main>
  );
}

export default Home;
