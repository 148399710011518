import React from "react";

function Sidebar() {
  return (
    <>
      <div className="flex h-screen w-screen lg:p-12 md:flex max-w-fit bg-gray-800 p-12 text-white flex-col space-x-2">
        <ul>
          <span></span>
          <li>
            <button>Dashboard</button>
          </li>
          <span></span>
          <li>
            <button>Projects</button>
          </li>
          <span></span>
          <li>
            <button>Skills</button>
          </li>
          <span></span>
          <li>
            <button>Emails</button>
          </li>
        </ul>
      </div>
    </>
  );
}

export default Sidebar;
