import React,{useEffect,useState} from "react";
import { CodeIcon } from "@heroicons/react/solid";
import { motion,useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import ProjectModal from "../modals/ProjectModal"


const projects = require("../data/data.json");

function ShowCaseProjects() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);

  const openModal = (project) => {
    setSelectedProject(project);
    setIsModalOpen(true);
    console.log(project)
  };

  const closeModal = () => {
    setSelectedProject(null);
    setIsModalOpen(false);
  };
  const controls = useAnimation();
  const [ref, inView] = useInView({
    triggerOnce: true,
  });

  useEffect(() => {
    if (inView) {
      controls.start({ opacity: 1, y: 0 });
    }
  }, [controls, inView]);
  return (
    <section id="projects" className="text-gray-400 bg-gray-900 body-font">
      <div className="py-10 mx-auto text-center lg:px-34">
        <div className="flex flex-col w-full mb-20">
          <CodeIcon className="mx-auto inline-block w-10 mb-4" />
          <h1 className="sm:text-4xl text-3xl font-medium title-font mb-1 text-white">
            Selected Projects
          </h1>
          
        </div>
        <div className="flex flex-wrap m-6 sm:m-3" ref={ref}>
          {projects.map((project) => (
            <div
              key={project.image}
              onClick={()=> openModal(project)}
              className="sm:w-1/3 w-full p-4"
            >
               <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={controls}
                  exit={{ opacity: 0, y: -40 }}
                  whileHover={{ scale: 1.1 }}
                  transition={{ type: "spring", stiffness: 40, damping: 10 }}
                >
              <div className="flex px-5 py-8">
                <div className="p-5 border border-gray-800 rounded-md elevation p-6 drop-shadow-2xl">
                <img
                  alt="project_gallery"
                  className="inset-0 w-full h-auto max-w-full object-cover object-center sm:object-contain rounded-md"
                  src={project.image}
                />
                <h2 className="text-left text-white pt-2">{project.title}</h2>
                <div className="flex justify-start" >
                     <button className="text-green-500">More details
                     <FontAwesomeIcon className="px-2" icon={faArrowRight} />
                      </button>
                </div>
                
                </div>
              </div>
              </motion.div>
            </div>
      
          ))}
        </div>
        {/* Render the modal only if a project is selected */}
       {isModalOpen && selectedProject && (
      <ProjectModal
         project={selectedProject}
          onClose={closeModal}/>
          
    
      )}
      </div>
       
    </section>
  );
}

export default ShowCaseProjects;
